import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import { Tooltip } from '@mui/material';

function LoginButton() {
    const { loginWithRedirect } = useAuth0();

    const handleLogin = useCallback(
        () => loginWithRedirect(),
        [loginWithRedirect],
    );

    return (
        <Tooltip arrow={true} title="login" data-cy="login">
            <IconButton sx={{
                borderRadius: 0,
                borderTop: 1,
                borderBottom: 1,
                borderLeft: 1,
                borderColor: 'grey.800',
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                width: 47,
            }} size='large' onClick={handleLogin} ><LoginIcon /></IconButton>
        </Tooltip>
    );
}

export default LoginButton;
