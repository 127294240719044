
import React, { memo } from 'react';
import TodoList from './TodoList';
// import Loader from '../Loader';
import Skeleton from '@mui/material/Skeleton'
import List from '@mui/material/List';

import Error from '../Error';
import TodoListDialog from './TodoListDialog';


function TodoListTable({
    todoLists, onDelete, onEdit, onView, loading, error, secondary, onShare, onUnshare, type, dense
}) {
    if (loading) {
        return (
            <Skeleton sx={{ margin: 2 }} animation="wave" />
        );
    } else if (error) {
        return (<Error error={error} />);
    } else if (todoLists.length === 0) {
        return (<> You don't have any to-do lists yet. </>);
    } else {
        return (
            <List sx={{ minWidth: "sm" }} dense={dense}>
                {todoLists.map((todoList) => (
                    <TodoList key={todoList.id} {...todoList}
                        secondary={secondary} type={type}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onView={onView}
                        onShare={onShare}
                        onUnshare={onUnshare}
                    />
                ))}
            </List>
        );
    }
}


export function TodoListList(params) {
    return (
        <>
            <TodoListDialog />
            <TodoListTable
                dense={params.dense}
                secondary={params.secondary}
                todoLists={params.todoLists}
                type={params.type}
                onDelete={params.onDelete}
                onEdit={params.onEdit}
                onView={params.onView}
                onShare={params.onShare}
                onUnshare={params.onUnshare}
                loading={params.loading}
                error={params.error}
            />
        </>
    );
}

export default memo(TodoListList)