
import { Button, Paper, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

export default function NotFound() {
    return (
        <>
            <Paper elevation={3} sx={{ margin: 2, maxWidth: 500 }}>
                <Box sx={{ margin: 2, padding: 2 }}>
                    <h1>404 - Not Found!</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                    <Button variant='outlined' placement='center' sx={{ margin: 2 }} startIcon={<HomeIcon />} >Return to homepage</Button>
                </Box>
            </Paper>
        </>
    )
}