import {
    useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
    useCallback,
    useMemo,
} from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/todo`;

const useTodoLists = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const getNumberOfTodoListsByUserId = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(`${baseUrl}/check/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    }, [getAccessTokenSilently]);

    const getAllByAuth0Id = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(`${baseUrl}/all/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return data;
    }, [getAccessTokenSilently]);


    const getAllMineByAuth0Id = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(`${baseUrl}/mine/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return data;
    }, [getAccessTokenSilently]);

    const getAllSharedByAuth0Id = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(`${baseUrl}/shared/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    }, [getAccessTokenSilently]);

    const getAllMySharedByAuth0Id = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(`${baseUrl}/myshared/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    }, [getAccessTokenSilently]);

    const deleteById = useCallback(async (id) => {
        const token = await getAccessTokenSilently();
        await axios.delete(`${baseUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }, [getAccessTokenSilently]);

    const getById = useCallback(async (id) => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(`${baseUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    }, [getAccessTokenSilently]);


    const save = useCallback(async (todoList) => {
        const token = await getAccessTokenSilently();
        todoList.creationDate = Date.now();
        todoList.modificationDate = Date.now();
        const {
            id,
            ...values
        } = todoList;
        const newTodoLists = await axios({
            method: id ? 'PUT' : 'POST',
            url: `${baseUrl}/${id ?? ''}`,
            data: values,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return newTodoLists;
    }, [getAccessTokenSilently]);

    const shareTodoListByEmail = useCallback(async (todoListId, email) => {
        const token = await getAccessTokenSilently();
        let values = {
            todoListId,
            email,
        };
        try {
            await axios({
                method: 'POST',
                url: `${baseUrl}/share`,
                data: values,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return email;
        } catch (error) {
            return 404;
        }

    }, [getAccessTokenSilently]);

    const unshareTodoListById = useCallback(async (todoListId) => {
        const token = await getAccessTokenSilently();
        await axios.delete(`${baseUrl}/share/${todoListId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }, [getAccessTokenSilently]);



    const todoListApi = useMemo(() => ({
        getAllByAuth0Id,
        getAllMineByAuth0Id,
        deleteById,
        save,
        getById,
        shareTodoListByEmail,
        getAllSharedByAuth0Id,
        getAllMySharedByAuth0Id,
        getNumberOfTodoListsByUserId,
        unshareTodoListById,
    }), [
        getAllByAuth0Id,
        getAllMineByAuth0Id,
        deleteById,
        getAllSharedByAuth0Id,
        save,
        getById,
        getAllMySharedByAuth0Id,
        getNumberOfTodoListsByUserId,
        shareTodoListByEmail,
        unshareTodoListById,
    ]);

    return todoListApi;
};

export default useTodoLists;