import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, ButtonGroup, } from '@mui/material';

import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

export default function AuthenticationButton() {
    const {
        isAuthenticated,
    } = useAuth0();

    if (isAuthenticated) {
        return (
            <LogoutButton />
        );
    }

    return <LoginButton />;
}
