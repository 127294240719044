import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Tooltip } from "@mui/material";

export default function UserAvatar() {
    const { user, isAuthenticated } = useAuth0();


    if (!isAuthenticated) {
        return null;
    } else {
        const { name, picture, givenName } = user;
        return (
            <Tooltip title={name} arrow={true}>
                <Avatar sx={{
                    height: 50, width: 48,
                    border: 1,
                    borderRadius: 0,
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                    borderColor: "grey.800",
                }} variant='rounded' src={picture} alt={givenName} />
            </Tooltip >
        );
    }

}