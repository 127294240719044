import React, { useEffect, useCallback } from 'react';

// Material Ui imports
import Checkbox from '@mui/material/Checkbox';
import { FormGroup, FormControlLabel } from '@mui/material';
import { Grid, List, Paper, Box } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';

// Custom imports
import TodoListList from './TodoListList';
import useTodoLists from '../../api/todoLists';
import useItems from '../../api/items';
import ViewTodoList from './ViewTodoList';
import Loader from '../Loader';
import ShareTodoList from './ShareTodoList';
import CustomSnackbar from '../CustomSnackbar';

export default function MyTodoLists() {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const { getAllByAuth0Id, getAllMineByAuth0Id, getAllSharedByAuth0Id, deleteById, getAllMySharedByAuth0Id,
        getNumberOfTodoListsByUserId, shareTodoListByEmail, unshareTodoListById } = useTodoLists();
    const { getAllByTodoListId } = useItems();

    const [dense, setDense] = React.useState(!matches);
    const [secondary, setSecondary] = React.useState(matches);

    const [loading, setLoading] = React.useState(false);
    const [loadingMine, setLoadingMine] = React.useState(false);
    const [loadingShared, setLoadingShared] = React.useState(false);
    const [loadingMyShared, setLoadingMyShared] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [errorShared, setErrorShared] = React.useState(null);
    const [errorMyShared, setErrorMyShared] = React.useState(null);

    const [myTodoLists, setMyTodoLists] = React.useState([]);
    const [sharedTodoLists, setSharedTodoLists] = React.useState([]);
    const [mySharedTodoLists, setMySharedTodoLists] = React.useState([]);
    const [totalMyTodoLists, setTotalMyTodoLists] = React.useState(0);
    const [totalSharedTodoLists, setTotalSharedTodoLists] = React.useState(0);
    const [totalMySharedTodoLists, setTotalMySharedTodoLists] = React.useState(0);

    const [shareEmail, setShareEmail] = React.useState('');
    const [sharedTodoList, setSharedTodoList] = React.useState(null);


    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(null);


    // for custom snackbar component
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackBarContent, setSnackbarContent] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
    const [snackbarDuration, setSnackbarDuration] = React.useState(1500);
    function handleSnackbar(params) {
        setSnackbarContent(params.content);
        setSnackbarSeverity(params.severity || 'success');
        setSnackbarDuration(params.duration || 1500);
        setSnackbarOpen(true);
    }

    // For viewing component
    const [viewOpen, setViewOpen] = React.useState(false);
    const [viewId, setViewId] = React.useState(null);
    const [todoListToView, setTodoListToView] = React.useState({
        name: '',
        description: '',
        items: [{}],
    });


    const [openShareDialog, setOpenShareDialog] = React.useState(false);
    const [idToShare, setIdToShare] = React.useState(null);

    const refreshCheck = useCallback(async () => {
        const data = await getNumberOfTodoListsByUserId();
    }, [getNumberOfTodoListsByUserId]);

    const refreshAllTodoLists = useCallback(async () => {
        try {
            setLoadingMine(true)
            setLoadingShared(true)
            setLoadingMyShared(true)
            setError(null);
            const data = await getAllByAuth0Id();
            setMyTodoLists(data.myTodoLists.items);
            setTotalMyTodoLists(data.myTodoLists.count);
            setSharedTodoLists(data.sharedTodoLists.items);
            setTotalSharedTodoLists(data.sharedTodoLists.count);
            setMySharedTodoLists(data.mySharedTodoLists.items);
            setTotalMySharedTodoLists(data.mySharedTodoLists.count);
        } catch (err) {
            setError(err);
        } finally {
            setLoadingMine(false)
            setLoadingShared(false)
            setLoadingMyShared(false)
        }
    }, [getAllByAuth0Id]);

    const refreshMyTodoLists = useCallback(async () => {
        try {
            setLoadingMine(true)
            setError(null);
            const data = await getAllMineByAuth0Id();
            setMyTodoLists(data.items);
            setTotalMyTodoLists(data.count);
        } catch (err) {
            setError(err);
        } finally {
            setLoadingMine(false)
        }
    }, [getAllMineByAuth0Id]);

    const refreshSharedTodoLists = useCallback(async () => {
        try {
            setLoadingShared(true);
            setErrorShared(null);
            const data = await getAllSharedByAuth0Id();
            setSharedTodoLists(data.items);
            setTotalSharedTodoLists(data.count);
        } catch (err) {
            setErrorShared(err);
        } finally {
            setLoadingShared(false);
        }
    }, [getAllSharedByAuth0Id]);

    const refreshMySharedTodoLists = useCallback(async () => {
        try {
            setLoadingMyShared(true);
            setErrorMyShared(null);
            const data = await getAllMySharedByAuth0Id();
            setMySharedTodoLists(data.items);
            setTotalMySharedTodoLists(data.count);
        } catch (err) {
            setErrorMyShared(err);
        } finally {
            setLoadingMyShared(false);
        }
    }, [getAllMySharedByAuth0Id]);

    const refreshTodoListItems = useCallback(async (todoList) => {
        try {
            setLoading(true);
            const data = await getAllByTodoListId(todoList.id);
            setTodoListToView({
                ...todoList,
                items: data.items,
            });
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [getAllByTodoListId, setTodoListToView]);

    const handleConfirmedDelete = useCallback(async () => {
        setOpenDeleteDialog(false);
        try {
            setLoadingMine(true);
            setError(null);
            setMyTodoLists((myTodoLists) => myTodoLists.filter(({ id }) => id !== idToDelete));
            setMySharedTodoLists((mySharedTodoLists) => mySharedTodoLists.filter(({ id }) => id !== idToDelete));
            setTotalMySharedTodoLists((totalMySharedTodoLists) => mySharedTodoLists.length);
            setTotalMyTodoLists((totalSharedTodoLists) => totalSharedTodoLists - 1);
            await deleteById(idToDelete);
            setLoadingMine(false);
            // of gewoon opnieuw ophalen
            // refreshAllTodoLists();
        } catch (err) {
            setError(err);
        }
    }, [deleteById, refreshAllTodoLists, idToDelete]);

    const handleDelete = useCallback(async (idToDelete) => {
        setIdToDelete(idToDelete);
        setOpenDeleteDialog(true);
    }, []);


    const handleView = useCallback(async (todoList) => {
        await refreshTodoListItems(todoList);
        setViewOpen(true);
    }, [refreshTodoListItems]);

    const handleShare = async (idToShare) => {
        setIdToShare(idToShare);
        setOpenShareDialog(true);
    };

    const handleUnshare = async (idToUnshare) => {
        let mine = myTodoLists.find(({ id }) => id === idToUnshare);
        try {
            setErrorShared(null);
            await unshareTodoListById(idToUnshare);
            setSharedTodoLists((list) => list.filter(({ id }) => id !== idToUnshare));
            setSharedTodoLists((list) => list.filter(({ id }) => id !== idToUnshare));
        } catch (err) {
            setErrorShared(err);
        }
        if (mine) {
            refreshMySharedTodoLists();
        } else {
            refreshSharedTodoLists();
        }
    };

    const handleShareSent = useCallback(async () => {
        setOpenShareDialog(false);
        let userTodoListId = await shareTodoListByEmail(idToShare, shareEmail);
        if (userTodoListId === 404) {
            handleSnackbar({ severity: 'error', content: 'User not found', setSnackbarDuration: 3000 })
        } else {
            refreshMySharedTodoLists();
            handleSnackbar({ severity: 'success', content: `Todo list shared with ${shareEmail}`, setSnackbarDuration: 6000 })
            setSharedTodoList("test")
        }
        setShareEmail('');
    }, [idToShare, shareEmail, shareTodoListByEmail, myTodoLists, handleSnackbar, sharedTodoList, refreshMySharedTodoLists, refreshSharedTodoLists, refreshMyTodoLists, refreshAllTodoLists, refreshCheck]);

    useEffect(() => {
        refreshCheck();
        refreshAllTodoLists();
        refreshMySharedTodoLists();

        // sadly matches reruns the api call but for the rest it runs like a charm :)
        setDense(!matches)
        setSecondary(matches)
    }, [refreshAllTodoLists, refreshCheck, refreshMyTodoLists, refreshSharedTodoLists, refreshMySharedTodoLists, matches]);

    return (
        <>
            <ShareTodoList
                open={openShareDialog}
                shareEmail={shareEmail}
                setShareEmail={setShareEmail}
                setOpen={setOpenShareDialog}
                idToShare={idToShare}
                sendShare={handleShareSent}
            />
            {/* <Loader loading={loading} /> */}
            <Grid sx={{ maxWidth: 900 }}>
                <ViewTodoList
                    open={viewOpen}
                    setOpen={setViewOpen}
                    viewId={viewId}
                    todoListToView={todoListToView}
                    setTodoListToView={setTodoListToView}
                />
                <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={dense}
                                    onChange={(event) => setDense(event.target.checked)}
                                />
                            }
                            label="Enable dense"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={secondary}
                                    onChange={(event) => setSecondary(event.target.checked)}
                                />
                            }
                            label="Show descriptions"
                        />
                    </FormGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={false}>
                            <h1>My to-do lists ({totalMyTodoLists})</h1>
                            <List dense={dense}>
                                <TodoListList
                                    dense={dense}
                                    secondary={secondary}
                                    todoLists={myTodoLists}
                                    type="mine"
                                    onDelete={handleDelete}
                                    onView={handleView}
                                    onShare={handleShare}
                                    loading={loadingMine}
                                    error={error} />
                            </ List>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={false}>
                            <h1>Shared with me ({totalSharedTodoLists})</h1>
                            <List dense={dense}>
                                <TodoListList
                                    dense={dense}
                                    secondary={secondary}
                                    todoLists={sharedTodoLists}
                                    type="shared"
                                    onUnshare={handleUnshare}
                                    onView={handleView}
                                    loading={loadingShared}
                                    error={errorShared} />
                            </ List>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={false}>
                            <h1>My shares ({totalMySharedTodoLists})</h1>
                            <List dense={dense}>
                                <TodoListList
                                    dense={dense}
                                    secondary={secondary}
                                    todoLists={mySharedTodoLists}
                                    type="myShared"
                                    onUnshare={handleUnshare}
                                    onView={handleView}
                                    loading={loadingMyShared}
                                    error={errorMyShared} />
                            </ List>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Dialog open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                sx={{
                    backdropFilter: "blur(10px)",
                }}
            >
                <Grid >
                    <DialogTitle >Delete to-do list?</DialogTitle>
                    <DialogContent>Are you sure you want to delete this to-do list? This action cannot be undone.</DialogContent>
                    <DialogActions >
                        <Button onClick={() => setOpenDeleteDialog(false)} color='success' data-cy="confirm-delete-to-do-list-button">Cancel</Button>
                        <Button onClick={() => handleConfirmedDelete()} color='error'>Delete</Button>
                    </DialogActions>
                </Grid>
            </Dialog>

            <CustomSnackbar open={snackbarOpen} autoHideDuration={snackbarDuration} setOpen={setSnackbarOpen} severity={snackbarSeverity} content={snackBarContent} />
        </>

    );
}