import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from "@mui/material/CssBaseline";
import Slide from '@mui/material/Slide';
import { List, ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText } from "@mui/material";
import useItems from "../../api/items";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TodoListItems(props) {
    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: 'auto',
            }}
        >
            <List sx={{ width: '100%' }}>
                {props.items.map((item) => {
                    const labelId = `checkbox-list-label-${item.id}`;
                    return (

                        <ListItem
                            key={'item' + item.id}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={props.handleToggle(item.id)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={!!item.status}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        data-cy="todo-list-item-checkbox"
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} data-cy="todo-list-item-contents" primary={item.content} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default function ViewTodoList({
    open,
    setOpen,
    viewId,
    todoListToView: {
        name,
        description,
        items,
    },
    setTodoListToView,
}) {
    const { toggleItemById } = useItems();

    function handleToggle(id) {
        return async () => {
            toggleItemById(id)
            const newItems = items.map((item) => {
                if (item.id === id) {
                    return { ...item, status: !item.status };
                }
                return item;
            });
            setTodoListToView({ name: name, description: description, items: newItems });
        };
    }

    const handleClose = () => {
        setOpen(false);
    };

    // useEffect(() => {
    //     setLocalItems();
    // }, [localItems, items]);

    return (
        <>
            <CssBaseline />
            <Dialog
                fullWidth
                maxWidth="md"
                TransitionComponent={Transition}
                onClose={handleClose}
                open={open}
                sx={{
                    backdropFilter: "blur(5px)",
                }}
            >

                <DialogTitle>{name}</DialogTitle>
                <DialogContent>{description}</DialogContent>
                <DialogContent>{TodoListItems({ items, handleToggle })}</DialogContent>
                <DialogContent>{viewId}</DialogContent>
                <DialogActions>
                    <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>
                </DialogActions>
            </Dialog>
        </>
    );
}