import { useAuth0 } from '@auth0/auth0-react';

import LogOutIcon from '@mui/icons-material/Logout';
import { Tooltip, IconButton } from '@mui/material';

function LogoutButton() {
    const { logout } = useAuth0();
    return (
        <Tooltip arrow={true} title="logout">
            <IconButton sx={{
                borderRadius: 0,
                borderTop: 1,
                borderBottom: 1,
                borderColor: 'grey.800',
                width: 47,
            }} onClick={() => logout({
                returnTo: window.location.origin
            })}
                data-cy="logout-button"
            > <LogOutIcon /> </IconButton>
        </Tooltip>
    );
}

export default LogoutButton;
