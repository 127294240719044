import {
    useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
    useCallback,
    useMemo,
} from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

const useUser = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const getUserByAuth0Id = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(baseUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return data;
    }, [getAccessTokenSilently]);


    const userApi = useMemo(() => ({
        getUserByAuth0Id,
    }), [getUserByAuth0Id]);

    return userApi;
}

export default useUser;