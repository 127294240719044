import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery'

import Home from './components/home/Home'
import Header from './components/home/Header'
import MyTodoLists from './components/todoLists/MyTodoLists'
import Workshop from './components/todoLists/Workshop'
import RequireAuth from './components/authentication/RequireAuth';
import NotFound from './components/NotFound'
import Login from './components/Login'

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const startThemeName = prefersDarkMode ? 'darkTheme' : 'lightTheme';
  const darkTheme = createTheme({ palette: { mode: 'dark', }, });
  const lightTheme = createTheme({ palette: { mode: 'light', }, });
  function handleStartTheme() {
    return startThemeName === 'darkTheme' ? darkTheme : lightTheme
  };
  const [currentTheme, setCurrentTheme] = React.useState(handleStartTheme());
  const [currentThemeName, setCurrentThemeName] = React.useState("systemTheme");
  const [location, setLocation] = React.useState(useLocation().pathname);

  const handleChangeLocation = (event, newLocation) => {
    setLocation(newLocation || location);
  };

  const handleChangeTheme = (event, newThemeName) => {
    setCurrentThemeName(newThemeName || currentThemeName)
    if (newThemeName === 'darkTheme') {
      setCurrentTheme(darkTheme);
    } else if (newThemeName === 'lightTheme') {
      setCurrentTheme(lightTheme);
    } else if (newThemeName === 'systemTheme') {
      setCurrentTheme(handleStartTheme());
    }
  };



  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Header
        darkTheme={darkTheme}
        lightTheme={lightTheme}
        handleChangeLocation={handleChangeLocation}
        handleChangeTheme={handleChangeTheme}
        currentThemeName={currentThemeName}
        location={location}
      />
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />

        <Route path="*" element={<Navigate to="/not-found" />} />
        <Route path="/not-found" element={<NotFound />} />

        <Route path="/my-to-do-lists" element={
          <RequireAuth> <MyTodoLists /> </RequireAuth>
        } />
        <Route path="/workshop" element={
          <RequireAuth> <Workshop /> </RequireAuth>
        } />
      </Routes>
    </ThemeProvider >
  );
}

export default App;
