import axios from 'axios';
import {
    useCallback,
    useMemo,
} from 'react';


const baseUrl = `${process.env.REACT_APP_API_URL}/health`;

const useHealth = () => {
    const getHealth = useCallback(async () => {
        try {
            const {
                status,
            } = await axios.get(`${baseUrl}/ping`);
            return status;
        } catch (error) {
            return 404;
        }
    }, []);

    const getVersion = useCallback(async () => {
        const {
            data,
        } = await axios.get(`${baseUrl}/version`);
        return data;
    }, []);

    const healthApi = useMemo(() => ({
        getHealth,
        getVersion,
    }), [getHealth, getVersion]);

    return healthApi;
}

export default useHealth;