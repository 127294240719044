import React, { memo } from 'react';
import { Snackbar, Alert } from '@mui/material';

export function SnackbarCustom(
    { open, setOpen, severity, content, autoHideDuration }
) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {content}
            </Alert>
        </Snackbar>
    )
}

export default memo(SnackbarCustom)