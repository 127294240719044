import { Link as RouterLink } from 'react-router-dom';
import React, { memo } from 'react';


// Material UI components
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import AuthenticationButton from '../authentication/AuthenticationButton';
import DevicesIcon from '@mui/icons-material/Devices';
import { Paper, ButtonGroup } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ServerInfo from './ServerInfo';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Tooltip from '@mui/material/Tooltip';
import UserAvatar from './UserAvatar';


function Header(
    {
        handleChangeTheme,
        handleChangeLocation,
        currentThemeName,
        location,
    }) {


    return (
        <Paper sx={{ m: 2, p: 2, pt: 1 }}>
            <ToggleButtonGroup
                sx={{ mr: 2, mt: 1 }}
                color="primary"
                value={location}
                exclusive
                onChange={handleChangeLocation}
            >
                <ToggleButton value="/home" component={RouterLink} to="/home">
                    <Tooltip disableFocusListener title="Home" arrow>
                        <HomeIcon />
                    </Tooltip>
                </ToggleButton>

                <ToggleButton value="/my-to-do-lists" component={RouterLink} to="/my-to-do-lists" data-cy="my-to-do-lists">
                    <Tooltip title="My To Do Lists" arrow>
                        <PlaylistAddCheckIcon />
                    </Tooltip>
                </ToggleButton>

                <ToggleButton value="/workshop" component={RouterLink} to="/workshop" data-cy="workshop">
                    <Tooltip title="Create To Do List" arrow>
                        <PlaylistAddIcon />
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
                sx={{ mr: 2, mt: 1 }}
                color="primary"
                value={currentThemeName}
                exclusive
                onChange={handleChangeTheme}
            >
                <ToggleButton value="lightTheme"><LightModeIcon /></ToggleButton>
                <ToggleButton value="darkTheme"><DarkModeIcon /></ToggleButton>
                <ToggleButton value="systemTheme"><DevicesIcon /></ToggleButton>
            </ToggleButtonGroup>


            <ButtonGroup sx={{ mt: 1 }}>
                <UserAvatar />
                <AuthenticationButton />
                <ServerInfo />

            </ButtonGroup>
        </Paper>
    );
}

export default memo(Header);
