import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, FormControl, DialogActions, Box, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import Error from '../Error';

export default function TodoListDialog() {
    const [open, setOpen] = React.useState(false);
    const [loadingCreateButton, setLoadingCreateButton] = React.useState(false);
    const [loadingCreateItemButton, setLoadingCreateItemButton] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [todoList, setTodoList] = React.useState({
        name: '',
        description: '',
        items: [],
    });

    async function getActiveTodoList() {
        return await new Promise(resolve => setTimeout(resolve, 500));
    }

    async function createTodoList() {
        return await new Promise(resolve => setTimeout(resolve, 1000));
    }
    const handleClose = async (event, reason) => {
        setLoadingCreateButton(true);
        await createTodoList()
        setLoadingCreateButton(false);
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleCreate = async (data, reason) => {
        setLoadingCreateButton(true);
        const { name, description } = data;
        try {
            setError(null);
            // await save({ name, description });
        } catch (err) {
            setError(err);
        }

        setLoadingCreateButton(false);
        if (reason !== 'backdropClick') {
            setOpen(false);
        }

    };

    const handleCancel = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleChange = (prop) => (event) => {
        setTodoList({ ...todoList, [prop]: event.target.value });
    };

    return (
        <>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>Create a new to-do list</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, maxWidth: 200 }}>
                            <TextField sx={{ margin: 1 }} label='name' id='todoListName'
                                value={todoList.name}
                                onChange={handleChange('name')} />
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 300 }}>
                            <TextField sx={{ margin: 1 }} label='description' id='todoListDescription'
                                value={todoList.description}
                                onChange={handleChange('description')} />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color='error'>Cancel</Button>
                    <LoadingButton onClick={handleCreate} color='success' loading={loadingCreateButton} >Create</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}