import {
    useAuth0,
} from '@auth0/auth0-react';
import axios from 'axios';
import {
    useCallback,
    useMemo,
} from 'react';

const baseUrl = `${process.env.REACT_APP_API_URL}/item`;

const useItems = () => {
    const {
        getAccessTokenSilently,
    } = useAuth0();

    const getAllByTodoListId = useCallback(async (todoListId) => {
        const token = await getAccessTokenSilently();
        const {
            data,
        } = await axios.get(`${baseUrl}/all/${todoListId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return data;
    }, [getAccessTokenSilently]);

    const deleteById = useCallback(async (id) => {
        const token = await getAccessTokenSilently();
        await axios.delete(`${baseUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }, [getAccessTokenSilently]);

    const saveAllByTodoListId = useCallback(async ({
        todoListId,
        items
    }) => {
        const token = await getAccessTokenSilently();
        let values = {
            todoListId,
            items
        }
        let addedItems = await axios({
            method: 'POST',
            url: `${baseUrl}/`,
            data: values,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return addedItems;
    }, [getAccessTokenSilently]);

    const toggleItemById = useCallback(async (id) => {
        const token = await getAccessTokenSilently();
        return await axios.put(`${baseUrl}/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }, [getAccessTokenSilently]);


    const itemApi = useMemo(() => ({
        getAllByTodoListId,
        deleteById,
        saveAllByTodoListId,
        toggleItemById,
    }), [getAllByTodoListId, deleteById, saveAllByTodoListId, toggleItemById]);

    return itemApi;
};

export default useItems;