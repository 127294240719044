import { memo, useCallback } from 'react';

import { ListItem, ListItemAvatar, ListItemText, Avatar, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import CancelIcon from '@mui/icons-material/Cancel';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Tooltip from '@mui/material/Tooltip';

import { useMediaQuery } from '@mui/material';

export function TodoList({
    id, name, user, modification_date, creationDate, type, description, onDelete, onEdit, onShare, onView, secondary, onUnshare, dense
}) {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const dateFormat = new Intl.DateTimeFormat('nl-BE', {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
    });

    const dateFormatSmall = new Intl.DateTimeFormat('nl-BE', {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

    const handleDelete = useCallback((event) => {
        event.preventDefault();
        onDelete(id);
    }, [id, onDelete]);

    const handleShare = useCallback((event) => {
        event.preventDefault();
        onShare(id);
    }, [id, onShare]);

    const handleUnshare = useCallback((event) => {
        event.preventDefault();
        onUnshare(id);
    }, [id, onUnshare]);

    const handleView = useCallback((event) => {
        event.preventDefault();
        onView({ id, name, creationDate, modification_date, description });
    }, [id, onView, name, creationDate, modification_date, description]);

    const renderIcons = () => {
        if (type === 'shared') {
            return (
                <>
                    <Tooltip title="Open in checklist viewer">
                        <IconButton edge="end" aria-label="edit" onClick={handleView}>
                            <PlaylistAddCheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="delete share" arrow={true}>
                        <IconButton edge="end" aria-label="cancel" onClick={handleUnshare}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </>
            );
        } else if (type === 'mine') {
            return (
                <>
                    <IconButton edge="end" aria-label="delete" onClick={handleDelete} data-cy="delete-to-do-list-button">
                        <DeleteIcon />
                    </IconButton>
                    <Tooltip title="Open in checklist viewer">
                        <IconButton edge="end" aria-label="edit" onClick={handleView} data-cy="todo-list-preview-button">
                            <PlaylistAddCheckIcon />
                        </IconButton>
                    </Tooltip>
                    <IconButton edge="end" aria-label="share" onClick={handleShare} data-cy="share-to-do-list-button">
                        <ShareIcon />
                    </IconButton>
                </>
            );

        } else if (type === 'myShared') {
            return (
                <Tooltip title="stop sharing" arrow={true}>
                    <IconButton edge="end" aria-label="cancel" onClick={handleUnshare} data-cy="delete-my-shared-to-do-list-button">
                        <CancelIcon />
                    </IconButton>
                </Tooltip>

            )
        }
    }

    const renderAvatar = () => {
        if (!matches) {
            return (
                <Tooltip title={`last changed: ${dateFormatSmall.format(new Date(modification_date))}`} arrow={true}>
                    <ListItemAvatar>
                        <Avatar>
                            <FormatListBulletedIcon />
                        </Avatar>
                    </ListItemAvatar>
                </Tooltip>
            )
        } else {
            return (
                <ListItemAvatar>
                    <Avatar>
                        <FormatListBulletedIcon />
                    </Avatar>
                </ListItemAvatar>
            )
        }
    }

    const renderListItem = () => {
        if (type == 'shared') {
            return (
                <ListItemText
                    primary={name}
                    secondary={secondary ? `${description} -- by -- ${user.name}` : null}
                />
            );
        } else {
            return (
                <ListItemText
                    primary={name}
                    secondary={secondary ? `${description}` : null}
                />
            );
        }
    }

    const renderDate = () => {
        if (matches) {
            return (
                <>
                    {dateFormat.format(new Date(modification_date))}
                </>
            )
        }
    }

    return (
        <>
            <ListItem key={id}>
                {renderAvatar()}
                {renderListItem()}
                {renderDate()}
                {renderIcons()}
            </ListItem>
        </>
    )
}

export default memo(TodoList);