
import useHealth from '../../api/health';

import React, { useEffect, useCallback } from 'react';
import { CircularProgress, Tooltip, IconButton } from '@mui/material';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudIcon from '@mui/icons-material/Cloud';


function reload() {
    window.location.reload();
}


export default function ServerInfo() {

    const [version, setVersion] = React.useState('');
    const [health, setHealth] = React.useState('');
    const [environment, setEnvironment] = React.useState('');
    const [name, setName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const { getHealth, getVersion } = useHealth();

    function handleLoading(bool) {
        setLoading(bool);
    }

    const checkServer = useCallback(() => {
        setLoading(true);
        let timer = setTimeout(async () => {
            let serverHealth = '';
            let serverVersion = {};
            try {
                serverHealth = await getHealth();
                serverVersion = await getVersion();

            } catch (error) {
                console.log(error);
                serverHealth = 500;
            }
            setHealth(serverHealth);
            setVersion(serverVersion.version);
            setEnvironment(serverVersion.env);
            setName(serverVersion.name);
            setLoading(false);
        }, 1500);

        return () => {
            clearTimeout(timer);
        }
    }, [getHealth, getVersion, setLoading, setEnvironment, setVersion, setName]);

    useEffect(() => {
        setLoading(true);
        handleLoading(true);
        checkServer()

    }, [getHealth, getVersion, setVersion, setEnvironment, checkServer, setName]);

    if (loading || health === '') {
        return (
            <Tooltip title={`Loading server info`} arrow={true}>
                <IconButton sx={{
                    borderRadius: 0,
                    border: 1,
                    borderColor: 'grey.800',
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    width: 47,
                }}>
                    <CircularProgress size={20} />
                </IconButton>
            </Tooltip>
        )
    } else if (health === 200) {
        return (
            <Tooltip title={`${name} - v${version} - running in ${environment}`} arrow={true}>
                <IconButton sx={{
                    borderRadius: 0,
                    border: 1,
                    borderColor: 'grey.800',
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    width: 47,
                }}
                    onClick={checkServer}
                >
                    <CloudIcon />
                </IconButton>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={`The backend-webserver is offline`} arrow={true}>
                <IconButton sx={{
                    borderRadius: 0,
                    border: 1,
                    borderColor: 'grey.800',
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    width: 47,
                }} onClick={() => reload()} >

                    <CloudOffIcon sx={{ color: "red" }} />
                </IconButton>
            </Tooltip>
        )

    }
}

