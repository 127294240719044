import React, { memo, useCallback } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ShareIcon from '@mui/icons-material/Share';

export function ShareDialog({
    open, setOpen, shareEmail, setShareEmail, sendShare,
}) {
    const handleClose = () => {
        setOpen(false);
        setShareEmail('');
    };

    return (
        <>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                sx={{
                    backdropFilter: "blur(10px)",
                }}
            >
                <DialogTitle>Share to-do list </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Share your to-do list with other users.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        fullWidth
                        margin="dense"
                        id="email"
                        label="email address"
                        type="email"
                        variant="standard"
                        value={shareEmail}
                        onChange={e => setShareEmail(e.target.value)}
                        data-cy="share-to-do-list-email-input"
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="error" onClick={handleClose}>Cancel</Button>
                    <Button startIcon={<ShareIcon />} color='success' onClick={sendShare} data-cy="share-to-do-list-submit">Share</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default memo(ShareDialog);