import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Paper, Box } from '@mui/material';
import Loader from './Loader';


export default function NotFound() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (!isLoading && isAuthenticated) {
        return <Navigate to="/" />;
    }
    if (!isLoading && !isAuthenticated) {
        return (
            <>
                <Paper sx={{ margin: 2, maxWidth: 500 }}>
                    <Box sx={{ margin: 2, padding: 2 }}>
                        <h1>Login required</h1>
                        <p>You must login to view this page!</p>
                    </Box>
                </Paper>
            </>
        )
    }

    return (
        <Loader />
    )
}