import React, { useEffect, useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useUser from '../../api/user';
import { Paper, Grid, IconButton, Skeleton } from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Link as RouterLink } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

export default function Home() {
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const { getUserByAuth0Id } = useUser();
    const [user, setUser] = React.useState({});
    const { isAuthenticated } = useAuth0();

    const handleLogin = useCallback(async () => {
        if (isAuthenticated) {
            const user = await getUserByAuth0Id();
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [getUserByAuth0Id, isAuthenticated]);

    const Greeting = () => {
        if (isAuthenticated) {
            return (
                <Paper sx={{ m: 2, p: 2, }}>
                    <h1>Home</h1>
                    <h2>Welcome {user.name}</h2>
                </Paper>
            );
        } else {
            return (
                <Paper sx={{ m: 2, p: 2 }}>
                    <h1>The To-do List App</h1>
                    <p>
                        In this page you can read about the features of the app.
                    </p>
                </Paper>
            );
        }
    };

    useEffect(() => {
        handleLogin();
    }, [handleLogin]);

    if (!matches) {
        return (
            <Grid container spacing={1}>
                <Grid item >
                    <Greeting />
                </Grid>
                <Grid item>
                    <Paper sx={{ m: 2, p: 2 }} >
                        <h2>What is this app?</h2>
                        <p>
                            This app is a simple to-do list app that allows you to create to-do lists and
                            share them with other users.
                        </p>
                        <p>
                            To get started, please login or register.
                        </p>
                    </Paper>
                </Grid>
                <Grid item >
                    <Paper sx={{ m: 2, p: 2 }}>
                        <h2>
                            <IconButton component={RouterLink} to="/my-to-do-lists"> <PlaylistAddCheckIcon /> </IconButton>
                            My to-do lists
                        </h2>
                        <Paper sx={{ m: 0.5, p: 2 }} elevation={2}>
                            <h3>Features</h3>
                            <p>
                                In this page you can view and share your to-do lists. The page lists three sections:
                            </p>
                            <ul>
                                <li>
                                    <b>My to-do lists</b>: This section lists all the to-do lists that you have created.
                                </li>
                                <li>
                                    <b>Shared with me</b>: This section lists all the to-do lists that have been shared with you.
                                </li>
                                <li>
                                    <b>Shared with others</b>: This section lists all the to-do lists that you have shared with others.
                                </li>
                            </ul>
                        </Paper >
                    </Paper>
                </Grid>
                <Grid item >
                    <Paper sx={{ m: 2, p: 2 }}>
                        <h2>
                            <IconButton component={RouterLink} to="/workshop"> <PlaylistAddIcon /> </IconButton>
                            My To Do Lists
                        </h2>
                        <p>
                            To create a to-do list, click on the "Create To Do List" button in the header.
                        </p>
                        <p>
                            To view your to-do lists, click on the "My To Do Lists" button in the header.
                        </p>
                        <p>
                            To view the to-do list workshop, click on the "To Do List Workshop" button in the header.
                        </p>
                    </Paper>
                </Grid>

            </Grid >

        )
    } else {
        return (
            <>
                <Grid container spacing={1} maxWidth={900}>
                    <Grid item xs={5}>
                        <Greeting />
                    </Grid>
                    <Grid item xs={7}>
                        <Paper sx={{ m: 2, p: 2 }}>
                            <h2>What is this app?</h2>
                            <p>
                                This app is a simple to-do list app that allows you to create to-do lists and
                                share them with other users.
                            </p>
                            <p>
                                To get started, please login or register.
                            </p>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper sx={{ m: 2, p: 2 }}>
                            <h2>
                                <IconButton component={RouterLink} to="/my-to-do-lists"> <PlaylistAddCheckIcon /> </IconButton>
                                My to-do lists
                            </h2>
                            <Paper sx={{ m: 0.5, p: 2 }} elevation={2}>
                                <h3>Features</h3>
                                <p>
                                    In this page you can view and share your to-do lists. The page lists three sections:
                                </p>
                                <ul>
                                    <li>
                                        <b>My to-do lists</b>: This section lists all the to-do lists that you have created.
                                    </li>
                                    <li>
                                        <b>Shared with me</b>: This section lists all the to-do lists that have been shared with you.
                                    </li>
                                    <li>
                                        <b>Shared with others</b>: This section lists all the to-do lists that you have shared with others.
                                    </li>
                                </ul>
                            </Paper >
                        </Paper>

                    </Grid>
                    <Grid item xs={6}>
                        <Paper sx={{ m: 2, p: 2 }}>
                            <h2>
                                <IconButton component={RouterLink} to="/workshop"> <PlaylistAddIcon /> </IconButton>
                                My To Do Lists
                            </h2>
                            <p>
                                To create a to-do list, click on the "Create To Do List" button in the header.
                            </p>
                            <p>
                                To view your to-do lists, click on the "My To Do Lists" button in the header.
                            </p>
                            <p>
                                To view the to-do lists, click on the "Open in checklist viewer" button on the My To Do Lists panel.
                            </p>
                        </Paper>

                    </Grid>
                </Grid>
            </>
        );
    };
};