import React, { useState, useEffect, useCallback } from 'react';
import { TransitionGroup } from 'react-transition-group';

// Material UI components
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { ButtonGroup, Collapse, Grid, List, Tooltip } from '@mui/material';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dialog, DialogTitle, DialogContent, FormControl, DialogActions, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';

// Custom components
import CustomSnackbar from '../CustomSnackbar';
import useTodoLists from '../../api/todoLists';
import useItems from '../../api/items';

import Error from '../Error';
import Loader from '../Loader';

async function createTodoList() {
    return await new Promise(resolve => setTimeout(resolve, 1000));
}

export default function TodoListWorkshop() {
    const { save, getAllMineByAuth0Id } = useTodoLists();
    const { saveAllByTodoListId } = useItems();

    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const [loading, setLoading] = useState(false);
    const [loadingCreateButton, setLoadingCreateButton] = useState(false);
    const [loadingCreateItemButton, setLoadingCreateItemButton] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [resetDialogOpen, setResetDialogOpen] = React.useState(false);

    const [newTodoList, setNewTodoList] = useState({
        name: '',
        description: '',
    });
    const [myTodoLists, setMyTodoLists] = useState([]);
    const [loadingMine, setLoadingMine] = useState(false);
    const [defaultProps, setDefaultProps] = useState({
        getOptionLabel: (option) => `${option.name} : ${option.description}`,
        isOptionEqualToValue: (option, value) => option.id === value.id,
    });


    const [itemCount, setItemCount] = useState(0);
    const [isTodoListSelected, setIsTodoListSelected] = useState(false);
    const [currentItemContent, setCurrentItemContent] = React.useState('');


    const handleChange = (event) => {
        console.log(event.target.value)
        setCurrentItemContent(event.target.value);
    };

    const [addedItems, setAddedItems] = React.useState([]);

    // for custom snackbar component
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackBarContent, setSnackbarContent] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
    const [snackbarDuration, setSnackbarDuration] = React.useState(1500);
    function handleSnackbar(params) {
        setSnackbarContent(params.content);
        setSnackbarSeverity(params.severity || 'success');
        setSnackbarDuration(params.duration || 1500);
        setSnackbarOpen(true);
    }

    const refreshMyTodoLists = useCallback(async () => {
        try {
            setLoadingMine(true)
            setError(null);
            const data = await getAllMineByAuth0Id();
            setMyTodoLists(data.items.map(todoList => {
                return {
                    name: todoList.name,
                    description: todoList.description,
                    id: todoList.id,
                }
            }))
        } catch (err) {
            setError(err);
        } finally {
            setLoadingMine(false)
        }
    }, [getAllMineByAuth0Id, setMyTodoLists, setError, setLoadingMine]);


    const [todoList, setTodoList] = React.useState({
        name: '',
        description: '',
        id: ''
    });
    const [todoListId, setTodoListId] = React.useState(0);
    const [todoListName, setTodoListName] = React.useState('');
    const [todoListDescription, setTodoListDescription] = React.useState('');

    const [loadingSaveButton, setLoadingSaveButton] = useState(false);


    const handleCreate = async (newTodoList) => {
        setLoadingCreateButton(true);
        try {
            setError(null);
            const { data } = await save({
                name: newTodoList.name,
                description: newTodoList.description,
            });
            await refreshMyTodoLists();
        } catch (err) {
            setError(err);
        }
        setNewTodoList({
            name: '',
            description: '',
        });
        setLoadingCreateButton(false);
        setOpen(false);
    };

    const handleSave = async () => {
        if (currentItemContent !== '') {
            setAddedItems([...addedItems, {
                content: currentItemContent,
                key: itemCount,
            }]);
            setCurrentItemContent('');
            handleSnackbar({
                content: `Added the current item before saving`,
                severity: 'warning',
                duration: 4000
            })
            setItemCount(itemCount + 1);
            return;
        }
        if (addedItems.length === 0) {
            handleSnackbar({
                content: `No items to save`,
                severity: 'warning',
                duration: 4000
            })
            return;
        }
        setLoadingSaveButton(true);
        let addedItemsWithoutKeys = addedItems.map(item => {
            return {
                content: item.content,
            }
        });

        try {
            setError(null);
            const { data } = await
                saveAllByTodoListId({
                    todoListId,
                    items: addedItemsWithoutKeys
                });
            setAddedItems([]);
            setItemCount(0);
            handleSnackbar({
                content: `Saved all ${itemCount} items to ${newTodoList.name}`,
            })
        } catch (err) {
            setError(err);
            handleSnackbar({
                content: `Failed to save items to ${todoList.name}`,
                severity: 'error',
            })

        }
        setLoadingSaveButton(false);
    };

    const handleCancel = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            handleReset();
        }
    };

    function AddItem(key) {
        if (currentItemContent !== '' && currentItemContent.length < 255) {
            const currentCurrentItem = {
                id: key,
                content: currentItemContent,
            }
            setItemCount(itemCount + 1);
            setAddedItems([...addedItems, currentCurrentItem]);
            setCurrentItemContent('');
        } else {
            handleSnackbar({
                autoHideDuration: 3000,
                severity: "error",
                content: 'The content of your to-do item is empty',
            });
        }
    }


    function handleDeleteItem(id) {
        const newAddedItems = addedItems;
        setAddedItems(newAddedItems.filter(item => item.id !== id));
        setItemCount(itemCount - 1);
    }

    function AddedItem(params) {
        return (
            <Box sx={{ margin: 1 }}>
                <TextField sx={{ minWidth: "sm" }} value={params.content} disabled
                    InputProps={{ startAdornment: <IconButton onClick={() => handleDeleteItem(params.id)} > <DeleteIcon /> </IconButton> }} />
            </Box >
        );
    }

    function AddedItemsList() {
        return (
            <List>
                <TransitionGroup>
                    {addedItems.map((item) => (
                        <AddedItem key={item.id} id={item.id} content={item.content} />
                    ))}
                </TransitionGroup>
            </List>
        );
    }

    const refreshTodoList = useCallback(async () => {
        setTodoList(myTodoLists.find(todoList => todoList.id === todoListId) || {});
    }, [myTodoLists, todoListId, setTodoList]);


    const handleNewTodoListChange = (prop) => (event) => {
        setNewTodoList({ ...newTodoList, [prop]: event.target.value });
    };

    const handleReset = () => {
        setResetDialogOpen(false)
        setAddedItems([]);
        setItemCount(0);
        setCurrentItemContent('');
        setIsTodoListSelected(false);
        setTodoListId(0);
        setTodoListName('');
        setTodoListDescription('');
    }

    const SaveButton = () => {
        if (!isTodoListSelected) {
            return (
                <Tooltip sx={{ p: 0, m: 0 }} arrow title="Select a to-do list first" aria-label="save">
                    <ButtonGroup >
                        <Button
                            variant="outlined"
                            color="success"
                            disabled={!isTodoListSelected}
                            onClick={() => handleSave()}>
                            Save
                        </Button>
                    </ButtonGroup>
                </Tooltip>
            )
        } else {
            return (
                <ButtonGroup >
                    <Button
                        variant="outlined"
                        color="success"
                        disabled={!isTodoListSelected}
                        data-cy="save-items-to-to-do-list-button"
                        onClick={() => handleSave()}>
                        Save
                    </Button>
                </ButtonGroup>
            )
        }
    }

    function renderInputTextField() {
        if (!isTodoListSelected) {
            return (
                <Tooltip title={"Select a to-do list first"} followCursor>
                    <TextField
                        fullWidth
                        disabled={!isTodoListSelected}
                        sx={{ maxWidth: 700 }}
                        value={currentItemContent}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment:
                                <IconButton
                                    disabled={!isTodoListSelected}
                                    onClick={() => AddItem(itemCount)}
                                >
                                    <AddIcon />
                                </IconButton>
                        }}
                    />
                </Tooltip>
            )
        } else {
            return (
                <TextField
                    fullWidth
                    disabled={!isTodoListSelected}
                    sx={{ maxWidth: 700 }}
                    value={currentItemContent}
                    onChange={e => setCurrentItemContent(e.target.value)}
                    data-cy="add-content-item-textfield"
                    InputProps={{
                        startAdornment:
                            <IconButton
                                disabled={!isTodoListSelected}
                                onClick={() => AddItem(itemCount)}
                                data-cy="add-item-button"
                            >
                                <AddIcon />
                            </IconButton>
                    }}
                />)
        }
    }

    const CreateButton = () => {
        if (matches) {
            return (
                <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <Button variant='contained' color='success' startIcon={<AddIcon />} onClick={() => { setOpen(true) }} data-cy="create-to-do-list-button">To-do List</Button>
                </Paper>
            )
        } else {
            return (
                <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <IconButton variant='contained' color='success' onClick={() => { setOpen(true) }} data-cy="create-to-do-list-button"><AddIcon /></IconButton>
                </Paper>
            )

        }
    }

    useEffect(() => {
        refreshTodoList();
        refreshMyTodoLists();

    }, [refreshMyTodoLists]);

    return (
        <>
            {/* <Loader loading={loading} /> */}

            <Grid sx={{ maxWidth: 900 }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <CreateButton />
                    </Grid>
                    <Grid item xs={9}>
                        <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                            <Autocomplete
                                {...defaultProps}
                                size='small'
                                onChange={(event, newValue) => {
                                    if (newValue === null) {
                                        handleReset()
                                        return;
                                    } else {
                                        setIsTodoListSelected(true);
                                        setTodoListId(newValue.id);
                                        setTodoListName(newValue.name)
                                        setTodoListDescription(newValue.description)
                                        // setTodoList({
                                        //     name: newValue.name,
                                        //     description: newValue.description,
                                        //     id: newValue.id
                                        // })
                                    }
                                    refreshTodoList();
                                }}

                                disablePortal
                                id="combo-box-demo"
                                options={myTodoLists}
                                renderInput={(params) => <TextField {...params} label="To-do list name" data-cy="select-to-do-list-text-field" />}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <Box sx={{ maxWidth: '100%' }}>
                        <h1 data-cy="todo-list-preview-name">Selected to-do list: {todoListName}</h1>
                        <p data-cy="todo-list-preview-description">{todoListDescription}</p>
                        <p>{todoListId === 0 ? null : todoListId}</p>
                    </Box>
                    <List dense component="div" role="list">
                        <h2>New to-do list items ({itemCount})</h2>
                        <Box>
                            {/* <CustomTextFieldWithToolTip /> */}
                            {renderInputTextField()}
                            <AddedItemsList />
                        </Box>
                    </List>
                </Paper>

                <Paper elevation={3} sx={{ margin: 2, padding: 2 }}>
                    <SaveButton />
                    <Button sx={{ ml: 2 }} variant="outlined" color="error" disabled={!isTodoListSelected} onClick={() => setResetDialogOpen(true)}>Reset</Button>
                </Paper>


                {/* Not visible by default */}
                <Dialog open={open}
                    onClose={handleCancel}
                    sx={{
                        backdropFilter: "blur(10px)",
                    }}
                >
                    <DialogTitle>Create a new to-do list</DialogTitle>
                    <DialogContent>
                        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <FormControl sx={{ m: 1, maxWidth: 200 }}>
                                <TextField sx={{ margin: 1 }} label='name' id='todoListName'
                                    value={newTodoList.name}
                                    onChange={handleNewTodoListChange('name')}
                                    data-cy="create-to-do-list-name-input"
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 300 }}>
                                <TextField sx={{ margin: 1 }} label='description' id='todoListDescription'
                                    value={newTodoList.description}
                                    onChange={handleNewTodoListChange('description')}
                                    data-cy="create-to-do-list-description-input"
                                />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color='error'>Cancel</Button>
                        <LoadingButton onClick={() => handleCreate(newTodoList)} color='success' loading={loadingCreateButton} data-cy="to-do-list-submit">Create</LoadingButton>
                    </DialogActions>
                </Dialog>

                <Dialog open={resetDialogOpen}
                    onClose={() => setResetDialogOpen(false)}
                    sx={{
                        backdropFilter: "blur(10px)",
                    }}
                >
                    <Grid >
                        <DialogTitle >Are you sure you want to delete all unsaved changes?</DialogTitle>
                        <DialogActions >
                            <Button onClick={() => setResetDialogOpen(false)} color='success'>Cancel</Button>
                            <Button onClick={() => handleReset()} color='error'>Reset</Button>
                        </DialogActions>
                    </Grid>
                </Dialog>
            </Grid >
            <CustomSnackbar open={snackbarOpen} autoHideDuration={snackbarDuration} setOpen={setSnackbarOpen} severity={snackbarSeverity} content={snackBarContent} />
        </>
    );
}
